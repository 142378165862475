import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

import { Row, Col } from 'react-styled-flexboxgrid'

import { Container as DefaultContainer, Link as StyledLink } from './styles'

import { useStaticQuery, graphql } from 'gatsby'

import { IoMdCheckmark } from 'react-icons/io'

import DefaultButton from './button'

import GatsbyImage from 'gatsby-image'

const Container = styled(DefaultContainer)`
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Button = styled(DefaultButton)`
    margin-bottom: 1rem;
`
const Link = styled(StyledLink)`
    margin-bottom: 1rem;
`

const Tick = styled(IoMdCheckmark)`
    font-size: 1rem;
    opacity: 0.5;
    margin-right: 0.5rem;
`

const Content = styled.div`
    padding-top: 1rem;
    @media ${props => props.theme.md} {
        padding-top: 0;
    }
`

const PageTitle = styled.h1`
    font-size: 1.3rem;
    @media ${props => props.theme.md} {
        margin-top: 0;
    }
`

const Features = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
`

const Feature = styled.li`
    line-height: 160%;
`

const Screenshot = styled(GatsbyImage)`
    max-height: 40vh;
    width: 60%;
    @media ${props => props.theme.sm} {
        width: 100%;
    }
`
const ImageContent = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media ${props => props.theme.sm} {
        display: none;
    }
`

const ImageWrapper = styled.div`
    display: flex;
`

const ImageTitle = styled.div`
    font-weight: 200;
    margin-bottom: 0.5rem;
`

const ImageSubtitle = styled.div`
    font-weight: 800;
    margin-bottom: 1rem;
`

const KnowMore = styled(Link)``

const features = [
    'Never pay to print a business card again!',
    'Easy to use, scan to save the business card',
    'Print your card as an event badge',
    "Protects your business's name.",
    'Eco-Friendly, we love our planet.',
    'Get live in 30 seconds!',
]

export default () => {
    const [verifiedPopupVisible, setVerifiedPopupVisible] = useState(false)

    const data = useStaticQuery(graphql`
        query {
            screenshot: file(relativePath: { eq: "screenshot.png" }) {
                childImageSharp {
                    # Specify a fluid image and fragment
                    # The default maxWidth is 800 pixels
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    useEffect(() => {
        let handle

        if (verifiedPopupVisible) {
            handle = setTimeout(() => setVerifiedPopupVisible(false), 2000)
        }

        return () => {
            clearTimeout(handle)
        }
    }, [verifiedPopupVisible])

    return (
        <Container>
            <Row>
                <Col xs={12} md={5}>
                    <ImageWrapper>
                        <ImageContent>
                            <ImageTitle>Eco Friendly,</ImageTitle>
                            <ImageSubtitle>Verified.</ImageSubtitle>

                            <KnowMore to={'/'}>Know more</KnowMore>
                        </ImageContent>
                        <Screenshot
                            fluid={data.screenshot.childImageSharp.fluid}
                            imgStyle={{ objectFit: 'contain' }}
                        />
                    </ImageWrapper>
                </Col>
                <Col xs={12} md={6}>
                    <Content>
                        <PageTitle>Verified business cards are here</PageTitle>
                        <Features>
                            {features.map((f, i) => (
                                <Feature key={i}>
                                    <Tick />
                                    {f}
                                </Feature>
                            ))}
                        </Features>

                        <Button to={'/create-business-card'}>
                            Create your business card now
                        </Button>
                    </Content>
                </Col>
            </Row>
        </Container>
    )
}
