import React from 'react'

import Layout from '../components/layout'

import SEO from '../components/seo'

import Footer from '../components/footer'

import Header from '../components/header'

import JanuaryLanding from '../components/january-landing'

export default () => {
    return (
        <Layout>
            <Header mode="site-header" />
            <SEO
                title={'Create your verified business card today!'}
                description={`In 30 seconds, you will never need to print a business card again. Get verified to enjoy the verified badge from verifeco and to protect your business name from misuse.`}
            />
            <JanuaryLanding />
            <Footer />
        </Layout>
    )
}
